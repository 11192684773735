.product-item {
    &-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: pinpoint-spacing(small);
        border: 1px solid $color-grey--light;
        border-radius: $global-radius;
    }

    &-photo {
        display: block;
    }

    .product-image {
        &-container {
            max-width: 100%;
        }

        &-wrapper {}

        &-photo {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: pinpoint-spacing(small);
    }

    &-name {
        flex: 1;
    }

    &-link {
        color: $color-black;

        &:hover {
            color: fade-out($color-black, 0.4);
        }
    }

    &-actions {
        display: flex;
        gap: pinpoint-spacing(small);
        flex-wrap: wrap;
        justify-content: center;

        .actions-primary {
            flex: 1;
            .action.primary {
                width: 100%;
            }
        }
    }
}

.products-list {
    .product-item-info {
        flex-direction: row;
    }
}

@import "product-card__extend";
