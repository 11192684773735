$amblog-featured-background-color: #f6f6f6; // TODO: Investigate why variable isn't overwritten in child theme
$amblog-featured-view-more-font-weight: 700 !default;

.column.main {
    overflow: hidden; // TODO: Investigate issue - This has been added to prevent sliders breaking the layout due to grid auto width
}

.widget.amblog-post-list {
  background-color: $amblog-featured-background-color;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 40px 0 75px !important;
  @include mq($from: desktop) {
    padding: 32px 16px !important;
  }

  .amblog-title {
    padding: 0 16px !important;
    margin-bottom: 32px !important;
    font-size: 27px !important;

    &::before {
      content: unset !important;
    }
  }

  .amblog-list.post-slider {
    padding: 0 16px !important;
  }

  .view-more.mobile {
    font-size: 18px !important;
    font-weight: $amblog-featured-view-more-font-weight !important;
    padding: 0 16px !important;
  }

  .post-item-details {
    .post-title {
      font-size: 18px !important;
      margin-bottom: 12px !important;
      @include mq($from: desktop) {
        font-size: 20px !important;
        margin-bottom: 16px !important;
          line-height: 1.2;
      }
    }

    .short-description {
      font-size: 16px !important;
      margin-bottom: 18px !important;
      margin-top: 0 !important;
      @include mq($from: desktop) {
        font-size: 18px !important;
        margin-bottom: 24px !important;
      }
    }

    .amblog-featured-card-bottom {
      margin-top: 0 !important;

      .ampost-link {
        font-size: 16px !important;

        @include mq($from: desktop) {
          font-size: 18px !important;
        }

        &::after {
          width: 24px !important;
          height: 24px !important;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          &::after {
            margin-left: 4px !important;
          }
        }
      }
    }
  }

  .amblog-date {
    display: none;
  }
}

.amblog-post-list:not(.sidebar .amblog-post-list) {
  padding: pinpoint-spacing(base) pinpoint-spacing(small);

  @include mq($from: desktop) {
    padding: pinpoint-spacing(large);
  }

    .slick-slide {
        &:last-child {
            @include mq($from: desktop) {
                margin-right: 0;
            }
        }
    }

  .amblog-title {
    padding-top: 0;
    @include pinpoint-font-size(24px);
    text-transform: none;
    display: flex;
    flex-direction: column;
    gap: pinpoint-spacing(small);
    white-space: nowrap;
    align-items: flex-start;
    color: $color-black;

    @include mq($from: tablet) {
      gap: pinpoint-spacing(base);
      flex-direction: row;
      align-items: center;
      max-width: var(--constrained-width);
      margin: var(--constrained-margin);
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: $color-secondary;
      position: unset;
      transform: none;
    }

    .view-more {
      display: none;

      @include mq($from: desktop) {
        display: flex;
        order: 3;
        color: $color-primary;
        &::after {
          transition: all 0.2s ease-in-out;
          transform: unset;
          rotate: -90deg;
        }

        &:hover {
          &::after {
            transform: translateY(5px);
          }
        }
      }
    }
  }
  .amblog-list.post-slider {
    padding: 0;
    max-width: var(--constrained-width);
    margin: var(--constrained-margin);
    position: relative;

    .amblog-slick-slider {
      padding: 0;

      .slick-track {
        margin: 0;
        width: 100%;
      }

      .post-item {
        background-color: $color-white;
        border-radius: $global-radius;

        &:first-child {
          margin-left: 0;
        }

        .post-image {
          display: block;

          img {
            aspect-ratio: 1.77/1;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .post-item-details {
          padding: pinpoint-spacing(base);
          margin-top: 0;

          .post-title,
          .short-description,
          .amblog-featured-card-bottom {
            text-align: left;
          }

          .post-title {
            @include pinpoint-font-size(18px);
          }

          .short-description {
            @include pinpoint-font-size(14px);
            margin-top: pinpoint-spacing(small);
            margin-bottom: pinpoint-spacing(small);
          }
        }
      }
    }

    .amblog-slick-slider:not(.slick-initialized) {
      display: flex;
      flex-direction: row;
      gap: pinpoint-spacing(base);
    }

    .slick-arrow {
      position: absolute;
      top: calc(100% + 32px);
      transform: unset;

      &::before {
        background-image: url(../images/icons/icon-chevron-right--white.svg);
        background-color: $color-primary;
        background-size: 28px 28px;
        width: 28px;
        height: 28px;
      }

      &.slick-prev {
        right: 52px;
        left: unset;
      }

      &.slick-next {
        right: 16px;
        left: unset;
      }
    }
  }

  .ampost-link,
  .view-more {
    font-weight: $amblog-featured-view-more-font-weight;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    @include pinpoint-font-size(14px);
    align-items: center;

    &::after {
      content: "";
      width: 24px;
      height: 24px;
      display: inline-flex;
      background-color: $color-primary;
      -webkit-mask-image: url(../images/icons/icon-chevron.svg);
      mask-image: url(../images/icons/icon-chevron.svg);
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
      transform: rotate(-90deg);
    }
  }

  .view-more.mobile {
    padding: 0 pinpoint-spacing(base);

    @include mq($from: desktop) {
      display: none;
    }
  }
}
