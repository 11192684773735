$related-product-title-text-transform: capitalize !default;
$related-product-title-font-weight: bold !default;

.products-grid {
    padding: 16px;
    margin-bottom: 16px;

    .product-items {
        gap: 16px;
        row-gap: 44px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        @include mq($from: tablet) {
            grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
        }

        @include mq($from: desktop) {
            grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
            gap: 32px;
        }
    }

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        overflow: hidden;

        .product-items {
            display: flex;
            padding: 0;

            .product-item {
                &-info {
                    background-color: $color-white;
                }
                &-name {
                    @include pinpoint-font-size(18px);
                }
                .price-box {
                    margin-top: auto;
                    .price {
                        @include pinpoint-font-size(24px);
                    }
                }
                .action.tocart.primary, .action.view-product.primary {
                    @include pinpoint-font-size(14px);
                    height: 40px;
                }
                .action.towishlist, .action.tocompare {
                    width: 56px;
                    height: 40px;
                    padding: 16px;
                }
                .product-image-photo {
                    border: 0;
                    border-radius: 0;
                }
            }

            .actions-primary {
                .action.view-product.primary {
                    a {
                        color: $color-white;
                    }
                }
            }
        }
    }
}

div {
    .mst-scroll__progress {
        display: none;
    }

    .mst-scroll__progress ~ .mst-scroll__progress {
        display: revert;
        padding: 0 16px;
    }
}

.block.upsell,
.block.related,
.block.crosssell,
.block-viewed-products-grid {
    position: relative;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    @include mq($from: desktop) {
        padding: 56px 124px !important;
    }

    @include mq($until: desktop) {
        padding: 48px 16px 128px !important;
    }

    .block-title.title {
        gap: 24px;
        @include mq($from: desktop) {
            @include pinpoint-font-size(30px);
        }
        @include mq($until: desktop) {
            @include pinpoint-font-size(27px);
        }
        margin-bottom: 40px;
        text-transform: $related-product-title-text-transform;
        strong {
            font-weight: $related-product-title-font-weight;
        }
        &::after {
            height: 5px;
        }
    }

    .slick-initialized {
        display: flex;
        position: unset;

        .slick-track {
            min-width: 100%;
            display: flex;
        }

        .slick-list {
            width: 100%;
        }

        .slick-slide {
            vertical-align: top;
            @include mq($from: desktop) {
                margin-left: 32px;
                max-width: calc(100% / 4);
            }
            @include mq($until: desktop) {
                margin-left: 16px;
            }
            margin-left: 32px;
            min-height: 100%;
            > div {
                height: 100%;
            }
            .product-item {
                height: 100%;
                max-width: unset !important; // An Amasty module is causing issue with max widths on product sliders
                .product-item-info {
                    @include mq($from: desktop) {
                        padding: 16px;
                    }
                    @include mq($until: desktop) {
                        padding: 6px;
                    }
                }
                .sku {
                    color: $color-sku;
                    @include pinpoint-font-size(14px);
                }
                .action.towishlist, .action.tocompare, .action.view-product.primary {
                    height: 40px;
                }
                .action.view-product.primary {
                    @include pinpoint-font-size(14px)
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
        .slick-arrow {
            &::before, &::after {
                content: unset;
            }
        }
        .slick-prev, .slick-next {
            position: absolute;
            bottom: 30px;
            left: 16px;
            top: unset;
            width: 40px;
            height: 40px;
            background-image: unset !important;
            background-color: $color-primary !important;
        }
        .slick-prev {
            left: 16px;
            @include mq($from: desktop) {
                background: none !important;
                top: 50%;
                left: 60px;
            }
            &::after {
                content: "";
                width: 24px;
                height: 24px;
                display: inline-flex;
                background-color: $color-white;
                -webkit-mask-image: url(../images/icons/icon-chevron.svg);
                mask-image: url(../images/icons/icon-chevron.svg);
                -webkit-mask-size: contain;
                mask-size: contain;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                transform: rotate(-90deg);
                @include mq($from: desktop) {
                    width: 50px;
                    height: 50px;
                    background-color: $color-grey;
                }
            }
        }
        .slick-next {
            left: 64px;
            @include mq($from: desktop) {
                right: 80px;
                left: inherit;
                background: none !important;
                top: 50%;
            }
            &::after {
                content: "";
                width: 24px;
                height: 24px;
                display: inline-flex;
                background-color: $color-white;
                -webkit-mask-image: url(../images/icons/icon-chevron.svg);
                mask-image: url(../images/icons/icon-chevron.svg);
                -webkit-mask-size: contain;
                mask-size: contain;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                transform: rotate(-90deg);
                @include mq($from: desktop) {
                    width: 50px;
                    height: 50px;
                    background-color: $color-grey;
                }
            }
        }
    }
    .products.wrapper {
        padding: 0;
        margin-bottom: 0;
        .slick-list {
            padding: 0;
        }
        .slick-slider {
            margin-bottom: 0;
        }
        .product-item {
            min-width: calc((100% - (32px * 3)) / 4);
            @include mq($until: desktop) {
                min-width: calc((100% - (16px * 3)) / 4);
            }
            @include mq($until: tablet) {
                min-width: calc((100% - (16px * 1)) / 2);
            }
        }
    }
}

.block-viewed-products-grid {
    .block-title {
        gap: 24px;
        margin-bottom: 40px;
        @include mq($until: desktop) {
            @include pinpoint-font-size(27px);
        }
        &::after {
            height: 5px;
        }
    }
    .products-grid {
        padding: 0;
        margin-bottom: 0;
    }
    .product-items.slick-initialized.slick-slider {
        padding: 0;
        margin-bottom: 0;
        .slick-list {
            padding: 0;
        }
        .slick-track {
          .slick-slide {
              .product-item {
                  &-info {
                      background-color: $color-white;
                  }
              }
          }
        }
        .slick-prev {
            @include mq($until: desktop) {
                left: 16px;
            }
        }
    }
}

.toolbar-filter-toggle-wrapper {
    @include mq($until: desktop) {
        min-height: 42px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: $color-grey--xlight;

        #toolbar-amount {
            display: none !important;
        }

        .toolbar-sorter {
            position: relative;
            width: 100%;
            justify-content: center;

            .sorter-label {
                border: unset !important;
                clip: unset !important;
                -webkit-clip-path: unset !important;
                clip-path: unset !important;
                height: unset !important;
                margin: unset !important;
                overflow: unset !important;
                padding: unset !important;
                position: unset !important;
                white-space: unset !important;
                width: 100% !important;
                text-align: center;

                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;

                &::after{
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: inline-flex;
                    background-color: $color-black;
                    mask-image: url(../images/icons/icon-chevron.svg);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                }
            }

            #sorter {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;

                @include mq($from: desktop) {
                    min-height: 56px;
                }
            }
        }
    }

    .toolbar-products {
        margin-bottom: 0;
        flex: 1 0 50%;
        padding: 0;
        justify-content: center;

        @include mq($from: desktop) {
            padding: 16px;
            justify-content: space-between;
        }
    }

    #filter-toggle {
        display: block;
        flex: 1 0 50%;
        text-align: center;
        border-left: 1px solid $border-colour;
        @include mq($from: desktop) {
            display: none;
        }
    }
}

.action.primary.mst-scroll__button:not(.compare) {
    display: block;
    max-width: fit-content !important;
    background: $color-white !important;
    color: $color-primary !important;
    border: 1px solid $color-primary !important;
    font-size: 20px !important;
    margin: 16px auto !important;

    &:hover {
        background: $color-primary !important;
        color: $color-white !important;
    }
}
