$product-card-item-name-font-size: 14px !default;
$product-card-item-name-font-weight: 500 !default;
$product-card-item-name-font-size--desktop: 18px !default;
$product-card-item-name-font-weight--desktop: bold !default;

.product-item {

    &-info {
        padding: 6px;

        @include mq($from: desktop) {
            padding: 16px;
        }
    }

    &-link{
        overflow-wrap: break-word;
        font-size: 16px;
        @include mq($from: tablet) {
            font-size: inherit;
        }
    }

    &-photo {
        margin-bottom: 4px;

        @include mq($from: desktop) {
            margin-bottom: 16px;
        }
    }

    &-name {
        font-size: $product-card-item-name-font-size;
        font-weight: $product-card-item-name-font-weight;
        line-height: 20px;

        @include mq($from: desktop) {
            font-size: $product-card-item-name-font-size--desktop;
            font-weight: $product-card-item-name-font-weight--desktop;
        }
    }

    &-details {
        .sku {
            font-size: 12px;
            color: $color-sku;
            margin-bottom: -8px;

            @include mq($from: desktop) {
                font-size: 14px;
            }
        }
    }

    .addto-favs {
        margin: 0 0 8px;
        @include mq($from: desktop) {
            margin: 0 0 16px;
        }
    }

    .price-box {
        @include mq($from: desktop) {
            margin-bottom: 12px;
        }

        .custom-price {

            .price-label {
                display: none;
            }
        }

        .price {
            font-size: 21px;

            @include mq($from: desktop) {
                font-size: 23px;
            }
        }

        .special-price {

            .price-label {
                display: none;
            }

            .price {
                color: $color-danger;
            }

            .price-including-tax {

                &:after {
                    color: $color-danger;
                }
            }

            .price-excluding-tax {

                &:after {
                    color: $color-danger;
                }
            }
        }

        .old-price {
            display: none;
        }

        .minimal-price-link {

            .price-wrapper {
                font-size: 21px;
                font-weight: bold;

                @include mq($from: desktop) {
                    font-size: 23px;
                }
            }
        }
    }

    &-actions {
        container-type: inline-size;

        .actions-primary {
            flex: 2;
            .action.tocart.primary {
                @include mq($from: desktop) {
                    font-size: 20px;
                }
            }
        }

        .actions-secondary {
            display: flex;
            flex-wrap: nowrap;
            gap: 6px;
            flex: 1;

            @include mq($from: desktop) {
                gap: 12px;
            }

            &:has(.block-requisition-list) {
                flex-wrap: wrap;

                @container (min-width: 250px) {
                    flex-wrap: nowrap;
                }
            }
        }
    }

    .block-requisition-list {
        width: 100%;
        order: -1;
        .requisition-list-action {
            width: 100%;
            height: 100%;

            .action.requisition-list-button.toggle.change {
                width: 100%;
                height: 100%;
                min-height: 46px;
                margin-left: 0;
                font-size: 14px;
                font-weight: bold;
                padding: 0 6px;
            }
            @include mq($until: desktop) {
                span {
                    white-space: normal;
                }
            }
        }
    }
    .action.tocompare,
    .split.button.wishlist {
        flex: 1;
        display: flex;
        .label.action.split {
           flex: 1;
        }
    }
}

.in-comparison-list {
    .action.tocompare {
        &::before {
            background-color: $color-primary;
        }
    }
}

.in-wishlist {
    .action.towishlist {
        &::before {
            background-color: $color-danger;
        }
    }
}
