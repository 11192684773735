$product-grid-item-width: 160px !default;
$product-grid-item-width--tablet: 280px !default;
$product-grid-item-width--desktop: 280px !default;


.products-grid {
    .product-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($product-grid-item-width, 1fr));
        gap: pinpoint-spacing(small);
        
        @include mq($from: tablet) {
            grid-template-columns: repeat(auto-fill, minmax($product-grid-item-width--tablet, 1fr));
        }

        @include mq($from: desktop) {
            grid-template-columns: repeat(auto-fill, minmax($product-grid-item-width--desktop, 1fr));
        }
    }

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            @include mq($from: tablet) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
        }
    }
}

.products-list {
    .product-items {
        display: flex;
        flex-direction: column;
        gap: pinpoint-spacing(small);
    }
}

@import "product-grid__extend";
